import React from 'react';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';

const CostPlot = ({ costPlotData, width }) => {
    console.log("costPlotData", costPlotData);
    const inverterCost = costPlotData.inverterCost;
    const reportCostData = costPlotData.reportCostData;
    const batteryCost = costPlotData.batteryCost;
    const panelCost = costPlotData.panelCost;
    return (
        <Plot
            data={reportCostData}
            layout={{
                height: 400,
                autosize: true,
                plot_bgcolor: "#ffffff",
                paper_bgcolor: "#ffffff",
                annotations: [
                    {
                        font: { size: 15 },
                        showarrow: false,
                        text: `R${(inverterCost + batteryCost + panelCost).toFixed(2)}`,
                        x: 0.5,
                        y: 0.5,
                    },
                ],
            }}
            useResizeHandler={true} // Enable auto-resizing
            style={{ width: '100%', height: '100%' }} // Ensure the plot takes full width and height
        />
    );
};

export default withDynamicWidth(CostPlot);