import React from 'react';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';

const DayNightSplitChart = ({ data, width }) => {
    return (
        <Plot
            data={data}
            layout={{
                width: width,
                height: 400, // Set a fixed height
                xaxis: { title: { font: { size: 18 } }, showgrid: false, zeroline: false, tickformat: '%d %b %Y' },
                yaxis: { title: { text: 'kWH', font: { size: 18 } }, showgrid: true, zeroline: true },
                plot_bgcolor: '#ffffff',
                barmode: 'stack',
                paper_bgcolor: '#ffffff',
                scrollZoom: true,
            }}
        />
    );
};

export default withDynamicWidth(DayNightSplitChart);