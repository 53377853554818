import React from 'react';

const SmallText = ({ children }) => {
    return (
        <p style={{ fontSize: '15px' }}>
            {children}
        </p>
    );
};

export default SmallText;