import React, { useEffect, useState } from "react";
import { Table, InputNumber, Collapse, Button, Alert, Flex, Space, Badge } from "antd";
import Plot from "react-plotly.js";
import { calc } from "antd/es/theme/internal";
import { processSizingReport, CalculateComponentSetup } from "../sizingComponentCalculations"; // Correct import
import jsPDF from "jspdf";

import debug from "debug";
import DailySizingChartComponent from "../charts/DailySizingChartComponent";
import WeeklySimulationChart from "../charts/WeeklySimulationChart";
import ConsumptionCapacityChart from "../charts/ConsumptionCapacityChart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    fa1,
    fa3,
    faBatteryFull,
    faSun,
    faList,
    faPlug,
    faMinus,
    faPlugCircleXmark,
    faQuestionCircle,
    faComputer,
    faGauge,
} from "@fortawesome/free-solid-svg-icons";
import InverterOperatingCapacityChart from "../charts/InverterOperatingCapacityChart";
import CostPlotChart from "../charts/CostPlotChart";
import { set } from "date-fns";

const log = debug("app:sizing_report");

const { Panel } = Collapse;

const SizingReport = (reportResponse, setFinalReportData) => {
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [reportCostData, setReportCostData] = React.useState([]);
    const [monitorUUID, setMonitorUUID] = React.useState("null");

    const [batteryCompMeetsRequired, setBatteryCompMeetsRequired] = React.useState(true);
    const [inverterCompMeetsRequired, setInverterCompMeetsRequired] = React.useState(true);
    const [panelCompMeetsRequired, setPanelCompMeetsRequired] = React.useState(true);

    const [inverterCount, setInverterCount] = useState(0);
    const [batteryCount, setBatteryCount] = useState(0);
    const [pvCount, setPvCount] = useState(0);

    const [inverterCost, setInverterCost] = React.useState(0);
    const [batteryCost, setBatteryCost] = React.useState(0);
    const [panelCost, setPanelCost] = React.useState(0);

    const [configSetupData, setConfigSetupData] = React.useState([]);

    const [clampsStatus, setClampsStatus] = useState({
        clamp1: { name: "CT Clamp 1", enabled: true, deduct_from_total: false },
        clamp2: { name: "CT Clamp 2", enabled: true, deduct_from_total: false },
        clamp3: { name: "CT Clamp 3", enabled: true, deduct_from_total: false },
    });


    const [sizingLiveData, setSizingLiveData] = React.useState([]);

    const [reportData, setReportData] = React.useState(null);
    const [
        inverterOperatingCapacityProjectionData,
        setInverterOperatingCapacityProjectionData,
    ] = React.useState([]);



    function generatePDF() {
        const doc = new jsPDF();
        doc.text("Sizing report for SAMPLE_NAME 2024/09/09", 10, 10);
        doc.save("sizing_report.pdf");
    }

    // Whenever changes are made to the count of the components, we pass them and calculate the sizing report details
    useEffect(() => {

        const processReport = async () => {
            const new_live_sizing_data = await processSizingReport(reportData, batteryCount, inverterCount, pvCount);
            setSizingLiveData(new_live_sizing_data);
        };

        if (reportData && batteryCount && inverterCount && pvCount !== undefined && pvCount !== null) {
            processReport();
        } 
    }, [pvCount, batteryCount, inverterCount, reportData]);



    useEffect(() => {
        log("Loaded Sizing Page 2");
        const cleaned_Data = reportResponse.reportResponse;

      


        const inverter_comp_results = cleaned_Data.system_results.inverter_result;
        const battery_comp_results = cleaned_Data.system_results.battery_result;
        let solar_panel_results = {};

        if (cleaned_Data.system_results.solar_panel_result) {
            solar_panel_results = cleaned_Data.system_results.solar_panel_result;
            setPvCount(solar_panel_results.no_units);
        }

        
        setClampsStatus(cleaned_Data.clampsStatus);
        setMonitorUUID(cleaned_Data.monitor_uid);

        setBatteryCount(battery_comp_results.no_units);
        setInverterCount(inverter_comp_results.no_units);


          // Process sizing report asynchronously
          const processReport = async () => {
            const new_live_sizing_data = await processSizingReport(cleaned_Data, battery_comp_results.no_units, inverter_comp_results.no_units, pvCount);
            setSizingLiveData(new_live_sizing_data);
        };

        processReport();





        // const barData = [
        //     {
        //         x: ["0-25%", "25-50%", "50-75%", "75-100%"],
        //         y: inverter_comp_results.operating_capacity_projections,
        //         type: "bar",
        //         marker: { color: "#1F77B4" },
        //         text: inverter_comp_results.operating_capacity_projections.map(
        //             (value) => `${value.toFixed(2)}%`
        //         ),
        //         textposition: "auto",
        //     },
        // ];
        // setInverterOperatingCapacityProjectionData(barData);

        const final_cost_of_inverters = parseFloat(
            inverter_comp_results.cost
        ).toFixed(2);
        const final_cost_of_batteries = parseFloat(
            battery_comp_results.cost
        ).toFixed(2);
        const finalInverterSavings = parseFloat(
            cleaned_Data.final_inverter_savings
        ).toFixed(2);
        const finalBatterySavings = parseFloat(
            cleaned_Data.final_battery_savings
        ).toFixed(2);

        setReportData(cleaned_Data);
        // setReportCostData(pieChartData);


        // setTotalSavings((parseFloat(finalBatterySavings) + parseFloat(finalInverterSavings)).toFixed(2));
        setInverterCost(parseFloat(inverter_comp_results.cost) || 0);
        setBatteryCost(parseFloat(battery_comp_results.cost) || 0);
        if (solar_panel_results && solar_panel_results.panel_cost) {
            setPanelCost(parseFloat(solar_panel_results.panel_cost) || 0);
        }


        const tableDatra =
            [
                {
                    key: "1",
                    category: "Inverter Technical",
                    components: "Deye SUN-8K",
                    output:
                        inverter_comp_results.inverter_rated_output_kw.toFixed(0) * inverter_comp_results.no_units,
                    requirement: inverter_comp_results.peak_required_kw.toFixed(2) + " kW",
                    quantity: inverter_comp_results.no_units,
                    calcOutput: inverter_comp_results.inverter_rated_output_kw,
                    calcCost: inverter_comp_results.cost_per_with_factor,
                },
                {
                    key: "2",
                    category: "Battery Technical",
                    components: battery_comp_results.battery_name,
                    output: battery_comp_results.backup_power_provided.toFixed(2) * battery_comp_results.no_units,
                    requirement:
                        battery_comp_results.backup_power_required.toFixed(2) + " kWh",
                    quantity: battery_comp_results.no_units,
                    calcOutput: battery_comp_results.backup_power_provided,
                    calcCost: battery_comp_results.cost,
                }

            ];

        if (solar_panel_results && solar_panel_results.name) {
            tableDatra.push({
                key: "3",
                category: "Panel Technical",
                components: solar_panel_results.name,
                output: (solar_panel_results.WP * solar_panel_results.no_units).toFixed(0),
                requirement: (solar_panel_results.required_solar_capacity_kw * 1000).toFixed(0) + " W",
                quantity: solar_panel_results.no_units,
                calcOutput: solar_panel_results.WP,
                calcCost: solar_panel_results.panel_price,
            });
        }

        setTableData(tableDatra);


    }, [reportResponse]);



    useEffect(() => {
        if (reportData) {
            determineSetup(reportData, batteryCount, inverterCount, pvCount);
        }
    }, [reportData, batteryCount, inverterCount, pvCount]);

    useEffect(() => {
        const pieChartData = [
            {
                values: [batteryCost, inverterCost, panelCost],
                labels: ["Total Battery Cost (ZAR)", "Total Inverter Cost (ZAR)", "Total Panel Cost (ZAR)"],
                type: "pie",
                hole: 0.6,
                textinfo: "label+value",
                texttemplate: "%{label}: R%{value}",
                textposition: "outside",
                automargin: true,
                marker: {
                    colors: ["#FF6347", "#1F77B4", "#F1E15B"], // Battery cost in red, inverter cost in blue
                },
            },
        ];
        setReportCostData(pieChartData);
    }, [inverterCost, batteryCost, panelCost]);


    const handleQuantityChange = (value, record) => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => record.key === item.key);

        let newInverterCount = inverterCount;
        let newBatteryCount = batteryCount;
        let newPvCount = pvCount;

        let item_requirement = record.requirement.split(" ")[0];

        let final_output = 0;


        if (index !== -1) {
            const item = newData[index];
            const newOutput = item.calcOutput * value;
            final_output = newOutput;

            newData[index] = {
                ...item,
                quantity: value,
                output: newOutput.toFixed(0),
            };
            setTableData(newData);

            if (item.category === "Inverter Technical") {
                const new_cost = parseFloat(item.calcCost * value);
                setInverterCost(new_cost);
                newInverterCount = value; // Update inverter count
            } else if (item.category === "Battery Technical") {
                const new_cost = parseFloat(item.calcCost * value);
                setBatteryCost(new_cost);
                newBatteryCount = value; // Update battery count
            } else if (item.category === "Panel Technical") {
                const new_cost = parseFloat(item.calcCost * value);
                setPanelCost(new_cost);
                newPvCount = value; // Update battery count
            }
        }

        return {
            newData,
            newInverterCount,
            newBatteryCount,
            newPvCount,
            final_output,
            item_requirement
        };
    };

    const editableColumns = [
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Recommended Components",
            dataIndex: "components",
            key: "components",
        },
        {
            title: "Components Output",
            dataIndex: "output",
            key: "output",
            render: (text, record) => {
                // Add the appropriate unit based on the category
                let unit;
                let meetsRequirement;
                switch (record.category) {
                    case "Inverter Technical":
                        unit = " kW";
                        meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                        setInverterCompMeetsRequired(meetsRequirement);
                        break;
                    case "Battery Technical":
                        unit = " kWh";
                        meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                        setBatteryCompMeetsRequired(meetsRequirement);
                        break;
                    case "Panel Technical":
                        unit = " W";
                        meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                        setPanelCompMeetsRequired(meetsRequirement);
                        break;
                    default:
                        unit = "";
                        meetsRequirement = true;
                }




                // Render with Badge
                return (
                    <div>
                        {!meetsRequirement ? (
                            <Badge status="warning" text={`${text}${unit}`} />
                        ) : (
                            `${text}${unit}`
                        )}
                    </div>
                );
            },
        },
        {
            title: "Requirement",
            dataIndex: "requirement",
            key: "requirement",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={text}
                    onChange={(value) => handleComponentQuanityChange(value, record)}
                />
            ),
        },
    ];


    const handleComponentQuanityChange = (value, record) => {

        const { newData, newInverterCount, newBatteryCount, newPvCount, final_output, item_requirement } = handleQuantityChange(value, record);


        // if (final_output < item_requirement) {
        //     setComponentsMeetRequirements(false);
        // }else {
        //     setComponentsMeetRequirements(true);
        // }
        setTableData(newData);
        setInverterCount(newInverterCount);
        setBatteryCount(newBatteryCount);
        setPvCount(newPvCount);
        // console.log("Inverter Count: ", newInverterCount);
    };

    const formatConfigSetupData = (data) => {
        return data.map(inverter => (
            <div key={inverter.inverter}>
                <strong>Inverter {inverter.inverter}:</strong>
                {inverter.mppts.map(mppt => (
                    <div key={mppt.mppt} style={{ paddingLeft: '20px' }}>
                        MPPT {mppt.mppt}: (String {mppt.mppt}: {mppt.panels} panels)
                    </div>
                ))}
                <div style={{ paddingLeft: '20px' }}>
                    Batteries: {inverter.batteries}
                </div>
            </div>
        ));
    };

    const determineSetup = (report_data, batteryCount, inverterCount, pvCount) => {
        const component_config_details = {
            inverterComponent: report_data.inverter_component,
            batteryComponent: report_data.battery_component,
            pvComponent: report_data.solar_component,
            overall_avg_kwh: report_data.system_results.overall_avg_kwh,
            backup_hours: report_data.system_results.backup_hours,
            overall_peak_watt: report_data.overall_peak_watt,
            batteryCount: batteryCount,
            inverterCount: inverterCount,
            pvCount: pvCount,
            pv_array_size: report_data.system_results.solar_panel_result.pv_array_size,
        }
        // console.log(component_config_details);
        const setupData = CalculateComponentSetup(component_config_details);
        console.log("Component Config Data: ", setupData);
        setConfigSetupData(setupData);
    };


    return (
        <div>
            <h1>Sizing Report</h1>
            <p>Below are the results calculated from the previous step.</p>

            <div>

                <Space size="small" className="monitor_block">
                    <FontAwesomeIcon
                        icon={
                            faGauge
                        }
                    />
                    {monitorUUID}
                </Space>
                <br />
                <br />

                {clampsStatus && (
                    <ul className="horizontal-list">
                        <li>
                            <Space size="small">
                                <FontAwesomeIcon
                                    icon={
                                        clampsStatus["clamp1"]["enabled"]
                                            ? faPlug
                                            : faPlugCircleXmark
                                    }
                                />
                                {clampsStatus["clamp1"]["name"]}
                                {clampsStatus["clamp1"]["deduct_from_total"] && (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ float: "right" }}
                                    />
                                )}
                            </Space>
                        </li>
                        <li>
                            <Space size="small">
                                <FontAwesomeIcon
                                    icon={
                                        clampsStatus["clamp2"]["enabled"]
                                            ? faPlug
                                            : faPlugCircleXmark
                                    }
                                />
                                {clampsStatus["clamp2"]["name"]}
                                {clampsStatus["clamp2"]["deduct_from_total"] && (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ float: "right" }}
                                    />
                                )}
                            </Space>
                        </li>
                        <li>
                            <Space size="small">
                                <FontAwesomeIcon
                                    icon={
                                        clampsStatus["clamp3"]["enabled"]
                                            ? faPlug
                                            : faPlugCircleXmark
                                    }
                                />
                                {clampsStatus["clamp3"]["name"]}
                                {clampsStatus["clamp3"]["deduct_from_total"] && (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ float: "right" }}
                                    />
                                )}
                            </Space>
                        </li>
                    </ul>

                )}
                <br />
            </div>

            <div>
                {reportData && reportData.system_results.type === 'hybrid' && (
                    <div>
                        <p>Weekly Simulation View Estimate</p>
                        {sizingLiveData && (
                            <WeeklySimulationChart
                                weeklyUsageSimulation={sizingLiveData.weeklyUsageSimulation}
                            />
                        )}
                    </div>
                )}
                <br />
            </div>

            <div>
                {reportData && reportData.system_results.type === 'hybrid' && (
                    <div>
                        <p>Daily Sizing View Estimate</p>
                        {sizingLiveData && (
                            <DailySizingChartComponent dailySizingViewEstimate={sizingLiveData.dailySizingViewEstimate} />
                        )}
                    </div>
                )}
                <br />
            </div>

            <div>
                {sizingLiveData && sizingLiveData.inverterOperatingCapacityProjectionData && (
                    <InverterOperatingCapacityChart data={sizingLiveData.inverterOperatingCapacityProjectionData} />
                )}
                <br />
            </div>

         

           



            <div>
                <p>Estimated Costs of Main Components</p>
                <p>
                    Costing results are based on using our recommended components below.
                </p>
                <div>

                    {/* <Plot
                    data={reportCostData}
                    layout={{
                        width: 800,
                        autosize: true,
                        plot_bgcolor: "#ffffff",
                        paper_bgcolor: "#ffffff",
                        annotations: [
                            {
                                font: { size: 15 },
                                showarrow: false,
                                text: `R${(inverterCost + batteryCost).toFixed(2)}`,
                                x: 0.5,
                                y: 0.5,
                            },
                        ],
                    }}
                /> */}
                    <CostPlotChart costPlotData={{ reportCostData, inverterCost, batteryCost, panelCost }} />
                </div>
                <p>Suggested Inverter: 1 x Deye SUN-8K</p>
            </div>

            <div>
            {(!batteryCompMeetsRequired || !inverterCompMeetsRequired || !panelCompMeetsRequired) && (
        <span>
            <Alert message="CAUTION" description="Some of your components don't meet their required output. Only proceed if you know what you are doing" type="warning" showIcon />
            <br />
        </span>
    )}

                <Table
                    dataSource={tableData}
                    columns={editableColumns}
                    pagination={false}
                    bordered
                    title={() => "Recommended Components Based On Calculations"}
                    footer={() =>
                        "* Component recommendations take into account the configuration and minimums required to setup the system optimally."
                    }
                />
            </div>


            <br />
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>


                <pre>{formatConfigSetupData(configSetupData)}</pre>

            </div>
            <br />


            <Collapse defaultActiveKey={["1"]}>
                <Panel header="View First Calculation Results Debug Information" key="1">
                    <div className="debug-element">
                        <pre>{JSON.stringify(reportData, null, 2)}</pre>
                    </div>
                </Panel>
            </Collapse>
            <br />
            <Collapse defaultActiveKey={["1"]}>
                <Panel header="View Second Calculation Results Debug Information" key="2">
                    <div className="debug-element">
                        <pre>{JSON.stringify(sizingLiveData, null, 2)}</pre>
                    </div>
                </Panel>
            </Collapse>
            <br />


            <Flex gap={"small"}>
                <Button type="primary" disabled> Generate Quote </Button>
                <Button type="primary" onClick={() => generatePDF()}> Save to PDF </Button>
            </Flex>
        </div>
    );
};

export default SizingReport;
