import React, { useState, useEffect } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { PlusOutlined, ThunderboltOutlined, ShoppingCartOutlined, UnorderedListOutlined, SettingOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Card, Skeleton, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { fetchMonitors } from '../services/apiService';
import TourComponent from './TourComponent';

function Home({ userData, setMonitorData, monitorData, businessData, isConnectedToBusiness }) {
    const [loading, setLoading] = useState(true);
    const [isBusinessAdmin, setisBusinessAdmin] = useState(false);

    const tour_steps = [
        {
            title: 'Welcome',
            description: 'Welcome to the dashboard! This is a quick tour to help you get started.',
            target: null,
        },
        {
            title: 'Shopping button',
            description: 'As you can see, the shopping button is located on the left side of the screen.',
            placement: 'right',
            targetId: 'shopping-button',
        },
        {
            title: 'New Monitor Sizing',
            description: 'This button allows you to create a new monitor sizing.',
            placement: 'top',
            targetId: 'new-monitor-sizing-button',
        },
        {
            title: 'New Quick Sizing',
            description: 'This button allows you to create a new quick sizing.',
            placement: 'top',
            targetId: 'new-quick-sizing-button',
        },
        {
            title: 'Projects',
            description: 'This button takes you to the projects page.',
            placement: 'top',
            targetId: 'projects-button',
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (!monitorData || monitorData.length === 0 || !businessData) {
                try {
                    const token = localStorage.getItem('accessToken');
                    const response_data = await fetchMonitors(token);

                    console.log("fetching data as there is none");
                    setMonitorData(response_data);
                    setLoading(false);
                    
                } catch (error) {
                    console.error('Failed to fetch monitors:', error);
                }
            } else {
                
                setLoading(false);
            }
        };
        if (userData.privilage){
            setisBusinessAdmin(userData.privilage.level === "1");
        }
        fetchData();
    }, []);

    return (
        <div style={{ margin: '10px' }}>
            <h1>Overview</h1>
            {isConnectedToBusiness ? (
               <p>Logged in as <strong>{userData.fullname}</strong> acting as <strong>{isBusinessAdmin ? 'Admin' : 'User'}</strong> for <strong>{businessData ? businessData['business_name'] : 'No Business Linked'}</strong></p>
            ) : (
                <p>Logged in as <strong>{userData.fullname}</strong></p>
            )}
            
          
          
            <Flex gap="small" wrap>
                <Link to="/categories">
                    <Button type="primary" icon={<ShoppingCartOutlined />} id="shopping-button">Shopping</Button>
                </Link>
                <Link to="/project-simple">
                    <Tooltip title={!isBusinessAdmin ? "Only admins can create new sizings" : ""}>
                        <Button type="primary" disabled={!isBusinessAdmin} icon={<PlusOutlined />} id="new-monitor-sizing-button">New Monitor Sizing</Button>
                    </Tooltip>
                </Link>
                <Link to="/quick-sizing">
                    <Tooltip title={!isBusinessAdmin ? "Only admins can create new quick sizings" : ""}>
                        <Button type="primary" disabled={!isBusinessAdmin} icon={<ThunderboltOutlined />} id="new-quick-sizing-button">New Quick Sizing</Button>
                    </Tooltip>
                </Link>
                <Link to="/projects">
                    <Button type="primary" icon={<UnorderedListOutlined />} id="projects-button">Projects</Button>
                </Link>
            </Flex>
            <br />
            {businessData ? (
                <div></div>
            ) : (
                <Alert message="No business was found linked to this account. To get started either have the business owner invite your account to contribute, or click here to register as a business." type="warning" showIcon />
            )}
            <Skeleton loading={loading} active>
                {monitorData.length > 0 ? (
                    <Flex gap="large" wrap>
                        {monitorData.map(monitor => (
                            <Card
                                key={monitor.id}
                                title={monitor.displayName}
                                style={{ width: 300 }}
                                actions={[
                                    <SettingOutlined key="setting" />,
                                    <EditOutlined key="edit" />,
                                    <EllipsisOutlined key="ellipsis" />,
                                ]}
                            >
                                <p>{monitor.description}<br /><strong>SN:</strong> {monitor.name} <br /><strong>Last:</strong> {new Date(monitor.updated).toLocaleString()}</p>
                            </Card>
                        ))}
                    </Flex>
                ) : (
                    <p>No monitors found.</p>
                )}
            </Skeleton>
            <br />

            {/* <div className='debug'>
                <pre>
                    {JSON.stringify(userData, null, 2)}
                </pre>
                <pre>
                    {JSON.stringify(businessData, null, 2)}
                </pre>
                </div> */}
                <TourComponent steps={tour_steps} tourKey="homeTourCompleted" />
        </div>
    );
}

export default Home;