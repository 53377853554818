import React, { useEffect, useState } from "react";
import { Table, Input, Tag, Select, Alert, Button, message, Form, Modal, notification } from "antd";
import axios from "axios";

import { fetchComponents, pushComponent } from '../services/apiService';

const { Search } = Input;
const { Option } = Select;

function ComponentsPage() {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filterType, setFilterType] = useState('All');
    const [filterMake, setFilterMake] = useState('All');
    // data
    const [components, setComponents] = useState([]);
    const [selectedComponentData, setSelectedComponentData] = useState(null);
    const [selectedComponentColumns, setSelectedComponentColumns] = useState([]);
    const [selectedComponentDataSource, setSelectedComponentDataSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [changes, setChanges] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [currentlyEditingUuid, setCurrentlyEditingUuid] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');
            const response = await fetchComponents(token);
            // console.log("response", response);
            setComponents(response);
            setData(formatData(response));
        };
        fetchData();
    }, []);

    const formatData = (components) => {
        const formattedData = [];
        components.inverters.forEach(inverter => {
            formattedData.push({
                key: inverter._id,
                uuid: inverter.component_uuid,
                name: inverter.inverter_name,
                make: inverter.make, // Add make field
                type: 'Inverter',
                description: `Rated Output: ${inverter.rated_output} kW`,
                price: `${inverter.price} ZAR`, // Add price field
            });
        });
        components.batteries.forEach(battery => {
            formattedData.push({
                key: battery._id,
                uuid: battery.component_uuid,
                name: battery.name,
                make: battery.make, // Add make field
                type: 'Battery',
                description: `kWh: ${battery.kwh}`,
                price: `${battery.price} ZAR`, // Add price field
            });
        });
        return formattedData;
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            filters: [
                { text: 'Inverter', value: 'Inverter' },
                { text: 'Battery', value: 'Battery' },
            ],
            onFilter: (value, record) => record.type === value,
            render: type => (
                <Tag color={type === 'Inverter' ? 'blue' : 'green'}>
                    {type.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            filters: Array.from(new Set(data.map(item => item.make))).map(make => ({
                text: make,
                value: make,
            })),
            onFilter: (value, record) => record.make === value,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button
                    type="primary"
                    onClick={() => handleEdit(record.uuid)}
                    disabled={currentlyEditingUuid === record.uuid}
                >
                    Modify
                </Button>
            ),
        },
    ];

    const handleSearch = (value) => {
        setSearchText(value);
        const filteredData = formatData(components).filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        setData(filteredData);
    };



    const handleEdit = (uuid) => {
        let component = components.inverters.find(item => item.component_uuid === uuid);
        let type = 'Inverter';

        if (!component) {
            component = components.batteries.find(item => item.component_uuid === uuid);
            type = 'Battery';
        }

        if (component) {
            const columns = Object.keys(component).map(key => ({
                title: key,
                dataIndex: key,
                key: key,
                editable: key !== '_id', // Make all cells editable except the _id cell
            }));

            const dataSource = [{
                key: component._id,
                type: type,
                ...component,
            }];

            setSelectedComponentColumns(columns);
            setSelectedComponentDataSource(dataSource);
            setSelectedComponentData(component);
            setCurrentlyEditingUuid(uuid);
            message.success('Component data loaded successfully!');
            checkForChanges(component, dataSource[0]);
        } else {
            message.error('Component not found.');
        }
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const [form] = Form.useForm();

        useEffect(() => {
            if (editing) {
                form.setFieldsValue({
                    [dataIndex]: record[dataIndex],
                });
            }
        }, [editing, form, record, dataIndex]);

        const toggleEdit = () => {
            setEditing(!editing);
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
                checkForChanges(selectedComponentData, { ...record, ...values });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form form={form} component={false}>
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        <Input onPressEnter={save} onBlur={save} />
                    </Form.Item>
                </Form>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps} className={editable ? 'editable-cell' : ''}>{childNode}</td>;
    };

    const handleSave = (row) => {
        const newData = [...selectedComponentDataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setSelectedComponentDataSource(newData);
        checkForChanges(selectedComponentData, newData[0]);
    };

    const mergedColumns = selectedComponentColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });

    const showModal = () => {
        const originalData = selectedComponentData;
        const modifiedData = selectedComponentDataSource[0];
        const changes = [];

        for (const key in originalData) {
            if (originalData[key] !== modifiedData[key]) {
                changes.push({
                    field: key,
                    original: originalData[key],
                    modified: modifiedData[key],
                });
            }
        }

        setChanges(changes);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        // Handle the save logic here
        // console.log('Changes confirmed:', changes);
        setIsModalVisible(false);
        const token = localStorage.getItem('accessToken');
        const formattedComponentData = {
            ...selectedComponentDataSource[0],
            type: selectedComponentDataSource[0].type.toLowerCase(),
        }; // The format is ok, we just need to drop key here or in the backend
        // console.log('formattedComponentData:', formattedComponentData);
        const pushedData = await pushComponent(token, formattedComponentData);
        notification.success({
            message: 'Changes saved successfully!',
            description: 'The component template has been updated successfully.',
        });
        // Update original data to current data
        setSelectedComponentData({ ...selectedComponentDataSource[0] });

        const updatedComponents = await fetchComponents(token);
        setComponents(updatedComponents);
        setData(formatData(updatedComponents));
        handleClear();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClear = () => {
        setSelectedComponentDataSource([]);
        setSelectedComponentColumns([]);
        setSelectedComponentData(null);
        setIsSaveDisabled(true);
        setCurrentlyEditingUuid(null);
    };

    const checkForChanges = (originalData, modifiedData) => {
        for (const key in originalData) {
            if (originalData[key] !== modifiedData[key]) {
                setIsSaveDisabled(false);
                return;
            }
        }
        setIsSaveDisabled(true);
    };

    const renderChangesTable = () => {
        const columns = [
            {
                title: 'Field',
                dataIndex: 'field',
                key: 'field',
            },
            {
                title: 'Original Value',
                dataIndex: 'original',
                key: 'original',
            },
            {
                title: 'Modified Value',
                dataIndex: 'modified',
                key: 'modified',
                render: (text, record) => (
                    <span style={{ backgroundColor: '#d4edda', padding: '2px 4px' }}>
                        {text}
                    </span>
                ),
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={changes.map((change, index) => ({ ...change, key: index }))}
                pagination={false}
                size="small"
            />
        );
    };

    return (
        <div>
            <h1>Components Page</h1>
            <Alert
                message="Caution"
                description="As a super admin, you are able to modify the global component templates for all users. Be careful and make sure you know what you are doing! These attribute changes will affect all calculations for sizing and other methods across this system."
                type="warning"
                showIcon
                style={{ marginBottom: 16 }}
            />
<div style={{ marginBottom: 16 }}>
    <Search
        placeholder="Search components"
        onSearch={handleSearch}
        style={{ width: 200 }}
    />
</div>
            <Table columns={columns} dataSource={data} />
            <div>
                <p>Modify the following Component Template. Click a cell to edit it</p>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    columns={mergedColumns}
                    dataSource={selectedComponentDataSource}
                    pagination={false}
                />
                <br />
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="primary" danger onClick={showModal} disabled={isSaveDisabled}> Update </Button>
                    <Button type="default" onClick={handleClear}> Clear </Button>
                </div>
            </div>
            <br />
            {/* <div className="debug-element">
                <pre>{JSON.stringify(components, null, 2)}</pre>
            </div> */}
            <Modal
                title="Confirm Update"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[ 
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" danger onClick={handleOk}>
                        Update
                    </Button>,
                ]}
            >
                <p>The following changes have been made:</p>
                {renderChangesTable()}
                <br />
                <Alert message="Please review the changes carefully before saving. This will affect all sizing calculations across the system" type="warning" showIcon />
                <p>Are you sure you want to update the attributes of this component template system-wide?</p>
            </Modal>
        </div>
    );
}

export default ComponentsPage;