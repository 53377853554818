import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout, Avatar, Spin, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Home from './components/Home';
import Profile from './components/Profile';
import Leads from './components/Leads';
import Projects from './components/Projects';
import MonitorSizing from './components/MonitorSizing';
import QuickSizing from './components/QuickSizing';
import Monitors from './components/Monitors';
import Categories from './components/Categories';
import Products from './components/Products';
import Product from './components/Product';
import Cart from './components/Cart';
import Faq from './components/Faq';
import NavBar from './components/NavBar';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import PublicMonitor from './components/PublicMonitor'; // Ensure this import is at the top of your file
import ComponentsPage from './components/ComponentsPage'; // Ensure this import is at the top of your file
import { fetchUserData, fetchBusinessUser } from "./services/apiService";
import Networks from './components/Networks';
import Feedback from './components/Feedback';
import EmailConfirmation from './components/EmailConfirmation';
import SolarPanelAngleTool from './components/SolarPanelAngleTool';
import Version from './components/Version';

const { Sider, Content } = Layout;

function AppContent() {
    const location = useLocation(); // Get the current path
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [username, setUsername] = useState('');
    const [userData, setUserData] = useState({});
    const [accessToken, setAccessToken] = useState('');
    const [monitorData, setMonitorData] = useState([]);
    const [businessData, setBusinessData] = useState([]);
    const [loading, setAppLoading] = useState(true);

    const [isConnectedToBusiness, setIsConnectedToBusiness] = useState(false); // If the user is associated with a business account

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        setAppLoading(false);
        setIsAuthenticated(false);
        setUsername('');
        setUserData({});
        setAccessToken('');
        setMonitorData([]);
        setBusinessData([]);
        console.log("Logged out");
    };

    const customBusinessHandler = (error) => {
        if (error.response && error.response.status === 400) {
            message.error('This Account is not linked to a business yet. Debug info');
        } else if(error.response && error.response.status === 401){
            message.warning('Session Expired. Please login again');
            //handleLogout();
        } 
        else {
            message.error('Failed to confirm your email. Please try again later.');
            console.log('Failed to confirm your email. Please try again later.', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                let [userResponse, businessResponse] = await Promise.all([
                    fetchUserData(token),
                    fetchBusinessUser(token, customBusinessHandler)
                ]);

                if (userResponse.status === 401) {
                    console.log("ACCESS TOKEN EXPIRED");
                    handleLogout();
                }

                const userData = userResponse.data;
                const businessData_resp = businessResponse;

                console.log("Fetching Business Data as there is none saved");
                setBusinessData(businessData_resp);
                setUsername(userData['fullname']);
                setIsConnectedToBusiness(!!businessData_resp); // Set isConnectedToBusiness based on whether business data is available

                if (businessData_resp && businessData_resp.users) {
                    console.log("Setting user data");
                    userData.privilage = businessData_resp.users
                        .filter(user => user.uuid === userData.uuid)[0];
                }
                else {
                    console.log("No business data found");
                    userData.privilage = { level: 0 };
                }

                if(!userData.isVerified){
                    message.info("User not verified");
                    handleLogout();
                }

                setUserData(userData);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setAppLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const authStatus = localStorage.getItem('isAuthenticated');
        if (authStatus === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {isAuthenticated && !loading && location.pathname !== '/public_monitor' ? (
                <>
                    <Sider collapsible style={{ position: 'sticky', top: 0, height: '100vh' }}>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', color: 'white' }}>
                            <img src="logo.png" alt="Scout Energy Logo" style={{ marginRight: '8px', width: '32px', height: '32px' }} />
                            <span>Scout Energy</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', color: 'white' }}>
                            <Avatar style={{ marginRight: '8px' }} icon={<UserOutlined />} />
                            <span>{userData['fullname']}</span>
                        </div>
                        <NavBar handleLogout={handleLogout} userData={userData} />
                    </Sider>
                    <Layout>
                        <Content style={{ margin: '16px' }}>
                            <Routes>
                                <Route path="/" element={<Home setUserData={setUserData} userData={userData} accessToken={accessToken} monitorData={monitorData} setMonitorData={setMonitorData} setBusinessData={setBusinessData} businessData={businessData} isConnectedToBusiness={isConnectedToBusiness} />} />
                                <Route path="/profile" element={<Profile businessData={businessData} userData={userData} setUserData={setUserData} setBusinessData={setBusinessData} />} />
                                {userData.isSuperAdmin && (
                                    <Route path="/components" element={<ComponentsPage userData={userData} />} />
                                )}
                                <Route path="/leads" element={<Leads userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>}  />
                                <Route path="/projects" element={<Projects userData={userData} isConnectedToBusiness={isConnectedToBusiness} />} />
                                <Route path="/project-simple/:sizing_id?" element={<MonitorSizing userData={userData} isConnectedToBusiness={isConnectedToBusiness} />}  />
                                <Route path="/quick-sizing" element={<QuickSizing userData={userData} isConnectedToBusiness={isConnectedToBusiness} />} />
                                <Route path="/monitors" element={<Monitors userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/categories" element={<Categories userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/products" element={<Products userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/product" element={<Product userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/cart" element={<Cart userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/faq" element={<Faq userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/tickets" element={<Feedback userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/networks" element={<Networks  userData={userData} isConnectedToBusiness={isConnectedToBusiness}/>} />
                                <Route path="/3Dtool" element={<SolarPanelAngleTool />} />
                            </Routes>
                        </Content>
                    </Layout>
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div>
                        <Routes>
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} />
                            <Route path="/confirmEmail/:token" element={<EmailConfirmation />} />
                            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setAppLoading={setAppLoading} setUsername={setUsername} setAccessToken={setAccessToken} />} />
                            <Route path="*" element={!isAuthenticated ? <Navigate to="/login" /> : <Navigate to="/" />} />
                        </Routes>
                    </div>
                </div>
            )}
            {/* Public Routes */}
            <Routes>
                <Route path="/public_monitor" element={<PublicMonitor />} />
            </Routes>
            <Version />
        </Layout>
    );
}

function App() {
    return (
        <Router basename="/app">
            <Routes>
                {/* Public Routes */}
                <Route path="/network/:network_id" element={<PublicMonitor />} />
                {/* Authenticated Routes */}
                <Route path="*" element={<AppContent />} />
            </Routes>
        </Router>
    );
}

export default App;