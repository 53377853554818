import React, { useEffect, useState } from "react";
import { fetchMonitors, fetchNetworks, createNetwork, deleteNetwork } from "../services/apiService"; // Ensure this import is correct
import { Table, Button, Modal, notification, Form, Input, Popconfirm, Tooltip } from "antd";

const MonitorManagement = ({ userData }) => {
    const [isBusinessAdmin, setIsBusinessAdmin] = useState(false);
    const [monitors, setMonitors] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [selectedMonitors, setSelectedMonitors] = useState([]);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [currentNetwork, setCurrentNetwork] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (userData && userData.privilage) {
            setIsBusinessAdmin(userData.privilage.level === "1");
          
        }

        const token = localStorage.getItem("accessToken");

        const fetchMonitorsData = async () => {
            try {
                const monitorsData = await fetchMonitors(token);
                // console.log("monitorsData", monitorsData);
                setMonitors(monitorsData);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        const fetchNetworksData = async () => {
            try {
                const networksData = await fetchNetworks(token);
                // console.log("networksData", networksData);
                setNetworks(networksData);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }

        fetchMonitorsData();
        fetchNetworksData();
    }, [userData]);

    const handleCreateNetwork = async (values) => {
        if (selectedMonitors.length === 0) {
            notification.error({
                message: 'No Monitors Selected',
                description: 'Please select at least one monitor to create a network.',
            });
            return;
        }

        const token = localStorage.getItem("accessToken");

        try {
            const newNetworkData = {
                name: values.name,
                business_id: userData.business_id, // Assuming userData contains business_id
                access_key: "hashed_access_key", // This should be generated securely
                description: values.description,
                created: new Date().toISOString(),
                monitors: selectedMonitors.map(monitor => monitor._id),
            };

            const network_id = await createNetwork(token, newNetworkData);
            const newNetwork = { _id: network_id, ...newNetworkData };
            notification.success({
                message: 'Network Created',
                description: `Network: ${values.name} created successfully.`,
            });

            setNetworks([...networks, newNetwork]);
            setIsCreateModalVisible(false);
            form.resetFields();
            setSelectedMonitors([]);
        } catch (error) {
            console.error("Failed to create network:", error);
            notification.error({
                message: 'Failed to Create Network',
                description: 'An error occurred while creating the network.',
            });
        }
    };

    const handleDeleteNetwork = async (networkId) => {
        const token = localStorage.getItem("accessToken");

        try {
            await deleteNetwork(token, networkId);
            notification.success({
                message: 'Network Deleted',
                description: 'The network has been deleted successfully.',
            });
            setNetworks(networks.filter(network => network._id !== networkId));
        } catch (error) {
            console.error("Failed to delete network:", error);
            notification.error({
                message: 'Failed to Delete Network',
                description: 'An error occurred while deleting the network.',
            });
        }
    };

    const handleViewShare = (network) => {
        setCurrentNetwork(network);
        setIsShareModalVisible(true);
    };

    const networkColumns = [
        {
            title: 'Network Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'View/Share',
            key: 'viewShare',
            render: (text, record) => (
                <Button type="primary" onClick={() => handleViewShare(record)}>
                    View/Share
                </Button>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure you want to delete this network?"
                    onConfirm={() => handleDeleteNetwork(record._id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Tooltip title={!isBusinessAdmin ? "Only business admins can delete networks" : ""}>
                    <Button type="danger" disabled={!isBusinessAdmin}>Delete</Button>
                    </Tooltip>
                </Popconfirm>
            ),
        },
    ];

    const monitorColumns = [
        {
            title: 'Display Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Updated',
            dataIndex: 'updated',
            key: 'updated',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedMonitors(selectedRows);
        },
    };

    const expandedRowRender = (network) => {
        const monitorData = monitors.filter(monitor => network.monitors.includes(monitor._id));
        return <Table columns={monitorColumns} dataSource={monitorData} rowKey="_id" pagination={false} />;
    };

    return (
        <div>
            <h1>Network Management</h1>
            <p>You can create networks and assign monitors to them in order to share with others outside the site.</p>
            <div>
                <h2>Networks</h2>
                <Table
                    dataSource={networks}
                    columns={networkColumns}
                    rowKey="_id"
                    expandable={{ expandedRowRender }}
                />
                <Tooltip title={!isBusinessAdmin ? "Only business admins can create new networks" : ""}>
                    <Button
                        type="primary"
                        onClick={() => setIsCreateModalVisible(true)}
                        disabled={!isBusinessAdmin}
                    >
                        Create New Network
                    </Button>
                </Tooltip>
            </div>
            <Modal
                title="Create New Network"
                visible={isCreateModalVisible}
                onOk={form.submit}
                width={800}
                onCancel={() => setIsCreateModalVisible(false)}
            >
                <Form form={form} onFinish={handleCreateNetwork}>
                    <Form.Item
                        name="name"
                        label="Network Name"
                        rules={[{ required: true, message: 'Please input the network name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please input the description!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
                <Table
                    dataSource={monitors}
                    columns={monitorColumns}
                    rowKey="_id"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                />
            </Modal>
            <Modal
                title="View/Share Network"
                visible={isShareModalVisible}
                onCancel={() => setIsShareModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setIsShareModalVisible(false)}>
                        Close
                    </Button>,
                    <Button
                    key="link"
                    type="primary"
                    href={`${window.location.origin}/network/${currentNetwork?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Go to Link
                </Button>,
                ]}
            >
                {currentNetwork && (
                    <div>
                        <p>Link: <a href={`${window.location.origin}/network/${currentNetwork?._id}`} target="_blank" rel="noopener noreferrer">{`${window.location.origin}/network/${currentNetwork?._id}`}</a></p>
                        <p>Access Code: {currentNetwork.access_key}</p>
                    </div>
                )}
            </Modal>
          
        </div>
    );
};

export default MonitorManagement;