import React, { useState, useRef, useEffect } from 'react';
import { Button, DatePicker, Select, Switch, Flex, Checkbox, Spin, message , Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong, faRightLong } from '@fortawesome/free-solid-svg-icons';
import { Radio } from 'antd';
import { fetchMonitorData, fetchMonitors, fetchAggregatedMonitorData } from '../services/apiService';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; 
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { calculatePeakData, calculateConsumptionAvgCapacityGraphData, calculateDayNightSplit, lineChartData, dailyKWHGraph, aggregateDailyKwh, weeklySimulationChartData, formatHourlyRawData } from './Calculations';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import LineChartComponent from './charts/LineChartComponent';

import { useErrorHandler } from './Util';
import DailyKWHChart from './charts/DailyKWHChart';
import PeakDataChart from './charts/PeakDataChart';
import DayNightSplitChart from './charts/DayNightSplitChart';
import WeeklyConsumptionChart from './charts/WeeklyConsumptionChart';
import ConsumptionCapacityChart from './charts/ConsumptionCapacityChart';

function Monitors({ isConnectedToBusiness }) {
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    const { Option } = Select;

    const plotRef = useRef();

    const [chartType, setChartType] = useState('minutely');
    const [loading, setLoading] = useState(true);
    const [business_monitors, setBusinessMonitors] = useState([]);
    const [selectedMonitorId, setSelectedMonitorId] = useState(null);

    const [startDate, setStartDate] = useState(dayjs().subtract(2, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));

    const [currentDay, setCurrentDay] = useState(dayjs().subtract(1, 'day'));
    const [IsCurrentDay, setIsCurrentDay] = useState(false);

    // New data handling
    const [monitorData, setMonitorData] = useState([]); // data for that day for the selected monitor

    const [isClamp1, setIsClamp1] = useState(true);
    const [isClamp2, setIsClamp2] = useState(true);
    const [isClamp3, setIsClamp3] = useState(true);

    const [dailyDataAggregation, setDailyDataAggregation] = useState([]);
    const [peakData, setPeakData] = useState([]);
    const [dailyKWData, setDailyKWData] = useState([]);
    const [dayNightSplit, setDayNightSplit] = useState([]);
    const [weeklyConsumptionData, setWeeklyConsumptionData] = useState([]);
    const [consumptionAvgProjection, setConsumptionAvgProjection] = useState([]);

    const [highestPeak, setHighestPeak] = useState(0);
    const [averageDailyUsage, setAverageDailyUsage] = useState(0);

    const [newLineData, setNewLineData] = useState([]);

    const [isThreePhase, setIsThreePhase] = useState(false);

    const [newRawHourlyReadings, setNewRawHourlyReadings] = useState([]);

    // Cache for monitor data
    const [monitorDataCache, setMonitorDataCache] = useState({});

    const handlePhase = (checked) => {
        setIsThreePhase(checked);
    };

    const handleError = useErrorHandler();

    const onChange1 = (e) => {
        console.log('radio checked', e.target.value);
        setChartType(e.target.value);
    };

    // as the monitor is selected we get the id of the monitor
    const handleChange = async (value) => {
        setSelectedMonitorId(value);
        console.log('Selected Monitor ID:', value);
        await fetchAndSetMonitorData(currentDay, value);
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const handleClamp1Change = (e) => {
        setIsClamp1(e.target.checked);
    };

    const handleClamp2Change = (e) => {
        setIsClamp2(e.target.checked);
    };

    const handleClamp3Change = (e) => {
        setIsClamp3(e.target.checked);
    };

    const handleStartDateChange = (date) => {
        if (date && date.isValid()) {
            if (date.isSameOrAfter(endDate, 'day')) {
                message.error('Start date must be before the end date.');
            } else {
                setStartDate(dayjs(date));
            }
        }
    };

    const handleEndDateChange = (date) => {
        if (date && date.isValid()) {
            if (date.isSameOrBefore(startDate, 'day')) {
                message.error('End date must be after the start date.');
            } else {
                setEndDate(dayjs(date));
            }
        }
    };

    const handleDateChange = async (date) => {
        setCurrentDay(date);
        await fetchAndSetMonitorData(date, selectedMonitorId);
    };

    const fetchAndSetMonitorData = async (date, monitorId) => {
        const formatted_date = date.format('YYYY-MM-DD');
        const cacheKey = `${monitorId}-${formatted_date}`;

        if (monitorDataCache[cacheKey]) {
            setMonitorData(monitorDataCache[cacheKey]);
        } else {
            try {
                const token = localStorage.getItem('accessToken');
                const temp_monitorData = await fetchMonitorData(token, formatted_date, monitorId);
                setMonitorData(temp_monitorData);
                setMonitorDataCache((prevCache) => ({
                    ...prevCache,
                    [cacheKey]: temp_monitorData,
                }));
            } catch (error) {
                handleError(error);
            }
        }
    }; 

    function goBackDay() {
        const newDay = dayjs(currentDay).subtract(1, 'day');
        setCurrentDay(newDay);
        fetchAndSetMonitorData(newDay, selectedMonitorId);
    }

    function goForwardDay() {
        const newDay = dayjs(currentDay).add(1, 'day');
        setCurrentDay(newDay);
        fetchAndSetMonitorData(newDay, selectedMonitorId);
    }

    useEffect(() => {
        setIsCurrentDay(dayjs().isSame(currentDay, 'day'));
    }, [currentDay]);

    useEffect(() => {
        const fetchMonitorsInfo = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const monitors_response = await fetchMonitors(token);
                console.log("here are monitors: ", monitors_response);
                setBusinessMonitors(monitors_response);
                //setSelectedMonitorId(monitors_response[0].id);
                handleChange(monitors_response[0].id);
                setLoading(false);
            } catch (error) {
                handleError(error);
            }
        };

        if (isConnectedToBusiness) {
        fetchMonitorsInfo();
        }
    }, []);

    useEffect(() => {
        if (selectedMonitorId) {
            fetchAndSetMonitorData(currentDay, selectedMonitorId);
        }
    }, [selectedMonitorId, currentDay]);
 
    useEffect(() => {
        if (selectedMonitorId) {
            const fetchAggregatedData = async () => {
                try {
                    const token = localStorage.getItem('accessToken');
                    const strt = startDate.startOf('day').format('YYYY-MM-DD');
                    const endd = endDate.endOf('day').format('YYYY-MM-DD');
                    const aggregatedData = await fetchAggregatedMonitorData(token, strt, endd, selectedMonitorId);
                    // console.log('Aggregated Data:', aggregatedData);
                    //setAggregatedMonitorData(aggregatedData);

                    // Check if aggregatedData.data is an object
                    if (aggregatedData.data && typeof aggregatedData.data === 'object') {
                        const clamp1vData = aggregatedData.data.clamp1v.data;
                        const clamp1iData = aggregatedData.data.clamp1i.data;
                        const clamp2vData = aggregatedData.data.clamp2v.data;
                        const clamp2iData = aggregatedData.data.clamp2i.data;
                        const clamp3vData = aggregatedData.data.clamp3v.data;
                        const clamp3iData = aggregatedData.data.clamp3i.data;
                    
                        // Assuming all data arrays have the same length
                        const newRawHourlyReadings = clamp1vData.map((_, index) => ({
                            clamp1v: clamp1vData[index],
                            clamp1i: clamp1iData[index],
                            clamp2v: clamp2vData[index],
                            clamp2i: clamp2iData[index],
                            clamp3v: clamp3vData[index],
                            clamp3i: clamp3iData[index],
                        }));
                    
                        console.log("New Raw Hourly Readings", newRawHourlyReadings);
                    
                        setNewRawHourlyReadings(newRawHourlyReadings);
                    } else {
                        console.error("aggregatedData.data is not an object", aggregatedData.data);
                    }

                    // console.log("formm", aggregatedData.data);
                    // const resukt = aggregateDailyData(aggregatedData.data);
                   
                    // console.log('Aggregated Data 2:', resukt);
                    // aggregateDailyData(monitorData['monitors'].find(monitor => monitor.id === value).data);
                    // setDailyDataAggregation(resukt);
                } catch (error) {
                    handleError(error);
                }
            };

            fetchAggregatedData();
        }
    }, [selectedMonitorId, startDate, endDate]);

    useEffect(() => {

        const formatted_hourly_data = formatHourlyRawData(newRawHourlyReadings);
        
  
        // const correctDailyKwh = aggregateDailyKwh(newRawHourlyReadings, isThreePhase);
        const newDailyKWData = dailyKWHGraph(newRawHourlyReadings, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase);
    
        const newPeakData = calculatePeakData(formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate);
    
        const newDayNightSplit = calculateDayNightSplit(formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);


        //const newDailyKWData = dailyKWHGraph(dailyDataAggregation, startDate, endDate, isClamp1, isClamp2, isClamp3);
        // const correctDailyKwh = aggregateDailyKwh(newRawHourlyReadings, isThreePhase); // working accurate calculation for the daily kwh
        // console.log("Correct Daily Kwh", correctDailyKwh);
        // const newDailyKWData = dailyKWHGraph(correctDailyKwh, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase);

        // const newPeakData = calculatePeakData(newRawHourlyReadings, isClamp1, isClamp2, isClamp3, startDate, endDate);

        // const newDayNightSplit = calculateDayNightSplit(newRawHourlyReadings, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);

        const weekly_response = weeklySimulationChartData(newRawHourlyReadings, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);
        const newWeeklyConsumptionData = weekly_response.newWeeklyConsumptionData;

        setHighestPeak(roundTo(weekly_response.highestPeak, 2));
        setAverageDailyUsage(roundTo(weekly_response.singleValueDailyAvg, 2));

        const newconsumptionAvgProjection = calculateConsumptionAvgCapacityGraphData(newRawHourlyReadings);

        setConsumptionAvgProjection(newconsumptionAvgProjection);

        setDailyKWData(newDailyKWData);
        setPeakData(newPeakData);
        setDayNightSplit(newDayNightSplit);
        setWeeklyConsumptionData(newWeeklyConsumptionData);
        
    }, [isClamp1, isClamp2, isClamp3, newRawHourlyReadings, startDate, endDate]);


    useEffect(() => {
        const newLineData = lineChartData(monitorData, currentDay, chartType);
        setNewLineData(newLineData);
    }, [monitorData, currentDay, chartType]);

    const generatePDF = () => {
        const input = plotRef.current;
        const graphIds = ['graph2', 'graph3', 'graph4']; // Replace with your actual graph IDs
        const graphTexts = [`Daily kWh for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Highest Peak Recorded Per Day kW for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Day/Night Consumption Breakdown kWH for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`]; // Corresponding texts for each graph
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 55;

        // Add title
        pdf.setFontSize(25);
        pdf.text('Monitoring Report', 10, 10);

        // Add monitor ID with bold text
        pdf.setFontSize(12);
        pdf.text('Monitor:', 10, 20);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${monitorData.displayName}`, 27, 20); // Adjust the x-coordinate as needed
        pdf.setFont('helvetica', 'normal');

        // Add date range with bold text
        pdf.text('Monitoring Period:', 10, 30);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, 47, 30); // Adjust the x-coordinate as needed
        pdf.setFont('helvetica', 'normal');

        graphIds.forEach((id, index) => {
            const graph = document.getElementById(id);
            if (graph) {
                html2canvas(graph, { scale: 2 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 210; // A4 width in mm
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    if (position + imgHeight + 10 > 297) {
                        pdf.addPage();
                        position = 10;
                    }

                    // Add text before each graph
                    pdf.setFontSize(12);
                    pdf.text(graphTexts[index], 10, position);
                    position += 10;

                    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    position += imgHeight + 10;

                    if (index === graphIds.length - 1) {
                        pdf.save('report.pdf');
                    }
                });
            } else {
                console.error(`Element with ID ${id} not found.`);
            }
        });
    };

    function roundTo(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Monitors Usage</h1>
                <Alert message="You are not connected to a business. Please connect to a business to view monitors." type="warning" showIcon />
            </div>
        );
    }

    return (
        <div>
            <h1>Monitors Usage</h1>
            <p>Select a monitor and then you can see the live usage and past usage.</p>
        
        <div style={{ border: '1px solid grey', borderRadius: '10px', padding: '10px', backgroundColor: 'white' }}>
       

            {!loading ? (
                business_monitors && business_monitors.length > 0 ? (
                    <Select placeholder="Select an item" 
                    onChange={handleChange}
                    value={selectedMonitorId}
                    >
                        {business_monitors.map((monitor) => (
                            <Option key={monitor.id} value={monitor.id}>{monitor.displayName}</Option>
                        ))}
                    </Select>
                ) : (
                    <p>No monitors available</p>
                )
            ) : (
                <Spin indicator={antIcon} />
            )}
            <br />
            <p>Monitor: <strong>{selectedMonitorId}</strong></p>
                <p>Monitoring Period: <strong>{dayjs(startDate).startOf('day').format('DD MMM')} to {dayjs(endDate).startOf('day').format('DD MMM')}</strong></p>
                <p>Average Usage Per Day: <strong>{averageDailyUsage} kWh</strong></p>
                <p>Highest Peak kW: <strong>{highestPeak} kW</strong></p>
        </div>
            <br />
            <div style={{ border: '1px solid black', borderRadius: '8px', padding: '10px', backgroundColor: 'white' }}>
                {!loading && monitorData ? (
                    <div>
                        <Flex style={{ justifyContent: 'space-between' }}>
                            <Flex gap="small">
                                <Button onClick={goBackDay}><FontAwesomeIcon icon={faLeftLong} /></Button>
                                <DatePicker value={currentDay} onChange={handleDateChange} />
                                <Button disabled={IsCurrentDay} onClick={goForwardDay}><FontAwesomeIcon icon={faRightLong} /></Button>
                            </Flex>
                            <Flex gap="small">
                                <Radio.Group onChange={onChange1} value={chartType} buttonStyle="solid">
                                    <Radio.Button value={'minutely'}>MINUTE GRAPH</Radio.Button>
                                    <Radio.Button value={'hourly'}>HOURLY GRAPH</Radio.Button>
                                </Radio.Group>
                            </Flex>
                        </Flex>
                        <p className='graph-label'>
                        {chartType === 'minutely' ? 'Clamp KW' : 'Hourly Clamps KWH'} for {dayjs(currentDay).format('DD MMM')}
                        </p>
                        <LineChartComponent data={newLineData}  format={chartType}/>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <br />
            <div style={{ border: '1px solid grey', borderRadius: '10px', padding: '10px', backgroundColor: 'white' }}>
                <Flex gap="small">
                    <DatePicker value={startDate} onChange={handleStartDateChange} />
                    <DatePicker value={endDate} onChange={handleEndDateChange} />
                </Flex>
                <br />
                <Checkbox checked={isClamp1} onChange={handleClamp1Change}>CT1</Checkbox>
                <Checkbox checked={isClamp2} onChange={handleClamp2Change}>CT2</Checkbox>
                <Checkbox checked={isClamp3} onChange={handleClamp3Change}>CT3</Checkbox>
                <div ref={plotRef} style={{ padding: '10px' }}>
                    <p className='graph-label'>Daily kWh for {dayjs(startDate).startOf('day').format('DD MMM')} to {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <div>
                        {!loading && dailyDataAggregation ? (
                            <div id='graph2'>
                          
                                <DailyKWHChart data={dailyKWData} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <p className='graph-label'>Highest Peak Recorded Per Day kW for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <p>This is the maximum of the clamps selected, added together per day</p>
                    <div>
                        {!loading && dailyDataAggregation ? (
                            <div id='graph3'>
                                <PeakDataChart data={peakData} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <p className='graph-label'>Day/Night Consumption Breakdown kWH for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <div>
                        {!loading && dailyDataAggregation ? (
                            <div id='graph4'>
                              <DayNightSplitChart data={dayNightSplit} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <p className='graph-label'>Weekly Average consumption in kW for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <div>
                        {!loading && weeklyConsumptionData ? (
                            <div id='graph5'>
                              <WeeklyConsumptionChart data={weeklyConsumptionData} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <p className='graph-label'>Average Consumption Capacity Projections (% of time) for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <div>
                        {!loading && consumptionAvgProjection ? (
                            <div id='graph5'>
                             <ConsumptionCapacityChart data={consumptionAvgProjection} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
               
               
                
                <br />
                <Flex gap="small">
                    <Button type="primary" onClick={generatePDF}>Generate Report PDF</Button>
                    <Button type="primary" disabled>RAW DATA EXPORT</Button>
                </Flex>
            </div>
           
        </div>
    );
}

export default Monitors;