import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

const { Panel } = Collapse;

/**
 * DebugElement component to display JSON data with styling.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data to be displayed.
 * @returns {JSX.Element} The rendered component.
 */
const DebugElement = ({ data }) => {
    return (
        <Collapse defaultActiveKey={["1"]}>
            <Panel header="View Debug Information" key="1">
                <div className="debug-element">
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            </Panel>
        </Collapse>
    );
};

DebugElement.propTypes = {
    data: PropTypes.object.isRequired,
};

export default DebugElement;