import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { confirmEmail } from '../services/apiService'; // Adjust the import path as needed
import { message } from 'antd';

const EmailConfirmation = () => {
    const { token } = useParams();
    const [status, setStatus] = useState('loading');
    const [resulttext, setResulttext] = useState('Confirming your email...');

    useEffect(() => {
        const confirmUserEmail = async () => {
            try {
                const result = await confirmEmail(token);
                setResulttext(result);
                
                if (result.status === 'success') {
                    setStatus('success');
                    message.success('Your email has been confirmed successfully!');
                } else {
                    setStatus('failure');
                    message.error('Invalid confirmation token.');
                }
            } catch (error) {
                setStatus('failure');
                message.error('Failed to confirm your email. Please try again later.');
            }
        };

        confirmUserEmail();
    }, [token]);

    return (
        <div>
            <pre>{JSON.stringify(resulttext['status'])}</pre>
            {resulttext['status'] === 'loading' && <p>Confirming your email...</p>}
            {resulttext['status'] === 'success' && <p>Your email has been confirmed successfully!</p>}
            {resulttext['status'] === 'failure' && <p>Failed to confirm your email. Please try again later.</p>}
            <p>Feel free to close this window, and navigate back to the login page</p>
        </div>
    );
};

export default EmailConfirmation;