import React, { useEffect, useState } from "react";
import { Steps, Button, Layout, Alert } from "antd";
import Sizing from "./sizing_components/Sizing";
import SizingReport from "./sizing_components/SizingReport";

const { Step } = Steps;
const { Content } = Layout;

const MonitorSizing = ({isConnectedToBusiness}) => {
    const [current, setCurrent] = useState(0);

    const [reportResponse, setReportResponse] = useState(null);

    // Here we send final report data and sizing details for pdf generation and quoting
    const [finalReportData, setFinalReportData] = useState(null);

    // Data
    const [reportSavingsData, setReportSavingsData] = useState([]);
    const [reportData, setReportData] = useState(null);
    const [totalSavings, setTotalSavings] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        if (reportResponse !== null) {
            next();
        }
    }, [reportResponse]);

    const steps = [
        {
            title: "Sizing",
            content: <Sizing setReportResponse={setReportResponse} />,
        },
        {
            title: "Sizing Report and Adjustments",
            content: (
                <SizingReport
                    reportResponse={reportResponse}
                    setFinalReportData={setFinalReportData}
                />
            ),
        },

    ];

    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Sizings</h1>
                <Alert message="You are not connected to a business. Please connect to a business work with sizings" type="warning" showIcon />
            </div>
        );
    }

    return (
        <Layout style={{ padding: "24px" }}>
            <Content>
                <Steps current={current}>
                    {steps.map((item, index) => (
                        <Step key={index} title={item.title} />
                    ))}
                </Steps>
                <div style={{ marginTop: "24px" }}>{steps[current].content}</div>
                <div style={{ marginTop: "24px" }}>
                    {current > 0 && (
                        <Button style={{ marginRight: "8px" }} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                  {current > 0 && current < steps.length - 1 && (
    <Button type="primary" onClick={() => next()}>
        Next
    </Button>
)}
                    {/* {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => alert("Submit")}>
                            Submit
                        </Button>
                    )} */}
                </div>
            </Content>
        </Layout>
    );
};

export default MonitorSizing;
