// components/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { api_endpoint } from './apiConfig';
import { loginUser } from '../services/apiService';

const Login = ({ setIsAuthenticated, setUsername, setAccessToken, setAppLoading }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
 
        setLoading(true);

        // Await the login response
        loginUser(values)
            .then(data => {
                // Check if the response is defined and contains the token
                if (data) {
                    console.log("login_response", data);

                    if (data.status === 'unauthorized') {
                        message.error('Invalid login credentials or user not verified. Make sure to verify your email before logging in.');
                    } else {
                        // Set authentication state and store token
                        setIsAuthenticated(true);
                        setAccessToken(data['access_token']);
                        localStorage.setItem('isAuthenticated', 'true');
                        localStorage.setItem('accessToken', data['access_token']);

                        // Navigate to the home page
                        setAppLoading(true);
                        navigate('/');
                        window.location.reload();
                    }
                } else {
                    console.error('Invalid login response:', data);
                    throw new Error('Invalid login response');
                }
            })
            .catch(error => {
                console.error('Login failed:', error);
                message.error('Login failed: ' + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <h1>Scout Energy</h1>
            <Form onFinish={onFinish} style={{ maxWidth: '300px' }}>
                <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                    <Input placeholder="email" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Login
                    </Button>
                </Form.Item>
            </Form>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to="/register">
                    <Button type="link">Register</Button>
                </Link>
            </div>
        </div>
    );
};

export default Login;