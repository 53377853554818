import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Select, Skeleton, notification, Row, Col, Modal } from "antd";
import { api_endpoint } from "./apiConfig";
import { fetchUserData, pushUserData, pushBusinessData, registerBusiness, fetchBusinessUser } from "../services/apiService";
import { debounce } from 'lodash';

function Profile({ businessData, userData, setBusinessData, setUserData }) {
  const [form] = Form.useForm();
  const [business_form] = Form.useForm();
  const [register_form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => async () => {
    try {
      if (userData) {
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch monitors:', error);
    }
  }, []);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  }

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const response = await pushUserData(accessToken, values);
      console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'User profile updated successfully.',
        });
        const updatedUserData = {
          ...userData,
          ...values,
        };
        setUserData(updatedUserData);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update user profile.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the profile.',
      });
    } finally {
      setButtonLoading(false);
    }
  };

  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState(true);

  // Debounce the validation function to prevent excessive calls
  const debouncedCheckRegisterFormValidity = debounce(async () => {
    try {
      await register_form.validateFields();
      setIsRegisterButtonDisabled(false);
    } catch (error) {
      setIsRegisterButtonDisabled(true);
    }
  }, 300);

  const onFinishBusiness = async (values) => {
    setBusinessLoading(true);
    try {
      const response = await pushBusinessData(accessToken, values);
      console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Business profile updated successfully.',
        });
        const updatedBusinessData = {
          ...businessData,
          ...values,
        };
        setBusinessData(updatedBusinessData);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update business profile.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the business.',
      });
    } finally {
      setBusinessLoading(false);
    }
    console.log('Business:', values);
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const onFinishRegister = async (values) => {
    setRegisterLoading(true);
    try {
      const response = await registerBusiness(accessToken, values);
      console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Business registered successfully.',
        });
        // Optionally update state or perform other actions
        const temp_data = {
          ...values,
        }
       
     
        
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to register business.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while registering the business.',
      });
    } finally {
      setRegisterLoading(false);
      setIsModalVisible(false);

      await delay(2000); // Wait for 2 seconds
      window.location.reload(); // Reload the page after registering a new business
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <Row>
          <Col span={24}>
            <h1 style={{ textAlign: 'left' }}>Profile</h1>
            <p style={{ textAlign: 'left' }}>Save your personal profile details.</p>
          </Col>
        </Row>
        <Skeleton loading={loading} active>
          <Form
            form={form}
            name="userProfile"
            onFinish={onFinish}
            initialValues={{
              email: userData ? userData.email : '',
              fullname: userData ? userData.fullname : '',
              address: userData ? userData.address : '',
              phone_number: userData ? userData.phone_number : '',
            }}
            style={{ maxWidth: '600px', margin: '0 auto' }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Full Name"
                  name="fullname"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Full Name!',
                    },
                  ]}
                >
                  <Input placeholder="Enter your full name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Email!',
                    },
                  ]}
                >
                  <Input disabled placeholder="Enter your email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone_number"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Phone Number!',
                    },
                  ]}
                >
                  <Input placeholder="Enter your phone number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Address!',
                    },
                  ]}
                >
                  <Input placeholder="Enter your address" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={buttonLoading}>
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </Skeleton>
      </div>
      {businessData ? (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <Skeleton loading={loading} active>
            <Row>
              <Col span={24}>
                <p style={{ textAlign: 'left' }}>More details about your business.</p>
              </Col>
            </Row>
            <Form
              form={business_form}
              name="businessProfile"
              onFinish={onFinishBusiness}
              initialValues={{
                business_email: businessData ? businessData.business_email : '',
                business_name: businessData ? businessData.business_name : '',
                business_phone_number: businessData ? businessData.business_phone_number : '',
                business_markup: businessData ? businessData.business_markup : '',
                business_vat: businessData ? businessData.business_vat : '',
                business_description: businessData ? businessData.business_description : '',
                business_website: businessData ? businessData.business_website : '',
                business_warehouse: businessData ? businessData.business_warehouse : '',
              }}
              style={{ maxWidth: '600px', margin: '0 auto' }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Business Phone Number"
                    name="business_phone_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Phone Number!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business phone number" type="tel" maxLength={15} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Business Email"
                    name="business_email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Business Name"
                    name="business_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Business Name',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Website"
                    name="business_website"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your Business Website',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business website" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Business Description"
                name="business_description"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Business Description',
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter a description of your business" rows={4} maxLength={200} />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Vat Number"
                    name="business_vat"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your VAT Number!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your VAT number" type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Markup"
                    name="business_markup"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your Markup!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your markup" type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Warehouse" name="business_warehouse">
                <Select defaultValue="Cape Town" style={{ width: 120 }} onChange={handleChange}>
                  {/* Add options here */}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={businessLoading}>
                  SAVE
                </Button>
              </Form.Item>
            </Form>
          </Skeleton>
        </div>
      ) : (
        <p>No business data available.</p>
      )}
      {!businessData && (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <Row>
            <Col span={24}>
              <Button type="primary" onClick={showModal}>
                Register a New Business
              </Button>
            </Col>
          </Row>
          <Modal
            title="Register a New Business"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              form={register_form}
              name="registerBusiness"
              onFinish={onFinishRegister}
              onFieldsChange={debouncedCheckRegisterFormValidity}
              style={{ maxWidth: '600px', margin: '0 auto' }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Business Name"
                    name="business_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Business Name',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Business Email"
                    name="business_email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Business Email',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Business Phone Number"
                    name="business_phone_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Business Phone Number',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business phone number" type="tel" maxLength={15} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Website"
                    name="business_website"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your Business Website',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your business website" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Business Description"
                name="business_description"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Business Description',
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter a description of your business" rows={4} maxLength={200} />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Vat Number"
                    name="business_vat"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your VAT Number!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your VAT number" type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Markup"
                    name="business_markup"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your Markup!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your markup" type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Warehouse" name="warehouse">
                <Select defaultValue="Cape Town" style={{ width: 120 }} onChange={handleChange}>
                  {/* Add options here */}
                </Select>
              </Form.Item>
              <Form.Item
                name="tos"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions')),
                  },
                ]}
              >
                <Checkbox>
                  I have read and agree to the <a href="/terms">Terms and Conditions</a>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={registerLoading} disabled={isRegisterButtonDisabled}>
                  Register New Business
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Profile;